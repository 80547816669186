import { ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ProduitData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import params from '~/params/produit/variant.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ProductVariant: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, produitId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <div className="mx-6">
      <Form<ProduitType>
        displayHidden
        docId={produitId}
        model={new ProduitData({ espaceId, params })}
        parentCollectionName="produits"
        type="update"
      />
    </div>
  </TemplateEspace>
);

export default requireEspace(ProductVariant);
